.pop-up_add {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pop-up_add .add-div {
  height: 30rem;
  width: 60rem;
  background-color: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  font-size: 2.2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.pop-up_add .add_skill {
  color: #00b6aa;
}

.custom_add_skill {
  border: 1.5px solid #00b6aa !important;
  height:50px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
