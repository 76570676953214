.file_upload {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  border: 1px solid #00b6aa;
  position: relative;
  overflow: hidden;
  background-color: #00b6aa;
  text-align: center;
  background-color: #00b6aa;
  color: white;
  font-size: 70px;
}

.password_group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .password_group {
    margin-top: 20px;
    margin-bottom: -5px;
  }
}

.image {
  /* height: 125px; */
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover !important;
  height: 100%;
}

input[type="file"] {
  position: absolute;
  bottom: 0%;
  left: 0%;
  height: 30px;
  width: 100% !important;
  background-color: #d0f4f1;
  border: none;
  opacity: 0;
  font-size: 100px;
  z-index: 20000;
}

.camera {
  background-color: #d0f4f1;
  position: absolute;
  bottom: 0%;
  left: 0%;
  position: absolute;
  z-index: 9;
  height: 30px;
  width: 100% !important;
  text-align: center;
  padding-top: 5px;
}

.profile_camera {
  position: absolute;
  left: 50px;
}
.loader {
  position: absolute;
  top: -75%;
  left: 1.7%;
  /* border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #00b6aa;
  border-bottom: 10px solid #00b6aa;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite; */
  z-index: 4567898765 !important;
}

.strip:after {
  background: white !important;
}

/* @-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.custom_firstname {
  padding-top: 1px;
  padding-right: 5%;
  padding-left: 0;
}

@media screen and (max-width: 767px) {
  .custom_firstname {
    padding: 1.5rem 5% 1.5rem 5%;
  }
}

@media screen and (max-width: 1023px) {
  .fixed_btn_for {
    position: fixed;
    bottom: 1%;
    right: 10%;
  }
}
