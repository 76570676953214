.searchBar-wrapper {
  padding: 1.5rem 5%;
  position: relative;
}

.searchBar_input-container {
  position: relative;
}

.searchBar-input {
  width: 40rem;
  padding: 1rem;
  padding-left: 0.5rem;
}
.searchBar-input::placeholder {
  color: #9b9b9b;
  font-size: 16px;
}
.searchBar-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #9b9b9b;
  font-size: 16px;
}
.searchBar-input:-ms-input-placeholder {
  /* IE 10+ */
  color: #9b9b9b;
  font-size: 16px;
}
.searchBar-input:-moz-placeholder {
  /* Firefox 18- */
  color: #9b9b9b;
  font-size: 16px;
}

.searchBar-button {
  padding: 0;
  background: none;
  border: none;
  height: 2rem;
  width: 2rem;
  margin-left: -28px;
}
.searchBar-button img {
  height: inherit;
}

.searchBar-heading {
  font-size: 1.6rem;
  font-weight: 400;
  padding-left: 5px !important;
}

.suggestion_container {
  width: 400px;
  border: 1px solid #00b6aa;
  margin-top: 1.5rem;
  border-radius: 4px;
  position: absolute;
  background-color: #fff;
  z-index: 3;
  box-shadow: 4px 7px 9px 0px #00000021;
}

@media screen and (max-width: 767px) {
  .suggestion_container {
    width:300px
  }
}

.suggestion_list {
  padding-inline-start: 0 !important;
  list-style: none;
  margin-bottom: 0;
  height: 44.4rem;
  overflow-y: scroll;
}

.suggestion_item {
  display: flex;
  justify-content: space-between;
  margin: 0 1.5rem;
  padding: 2rem 0;
  font-size: 1.4rem;
  letter-spacing: 0.51px;
  color: #4a4a4a;
  align-items: center;
}
.suggestion_item:not(:last-child) {
  border-bottom: solid 2px #ecedf4;
}

.suggestion_add {
  width: 9rem;
  height: 3rem;
  background-color: #00b6aa;
  border-radius: 0.4rem;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.79;
  letter-spacing: 0.51px;
}

.add_custom_skills_button img {
  width: 2rem;
  margin-right: 1rem;
}

.addSkill_overlay {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.addSkill_popUp {
  width: 55.5rem;
  height: 25rem;
  background-color: #fff;
  padding: 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.addSkill_header {
  display: flex;
  justify-content: space-between;
  font-size: 2.2rem;
  color: #007e76;
  font-weight: 500;
  align-items: center;
}
.addSkill_header h2 {
  font-size: inherit;
  margin: 0;
}

.addSkill_close {
  background-color: transparent;
  border: none;
  color: #00b6aa;
  font-size: 2.5rem;
}

.addSkill_wrapper {
  width: 46.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.addSkill_input {
  border-radius: 4px;
  border: solid 1px #00b6aa !important;
  padding: 0.8rem 2rem;
}

.addSkill_controls {
  display: flex;
  justify-content: flex-end;
}

.addSkill_buttons,
.addSkill_buttons:active,
.addSkill_buttons:focus,
.addSkill_buttons:hover {
  border: solid 1px #00b6aa;
  width: 138px;
  padding: 1.2rem 1.5rem 1.1rem;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  color: #007e76;
}
.addSkill_buttons:not(:last-child),
.addSkill_buttons:active:not(:last-child),
.addSkill_buttons:focus:not(:last-child),
.addSkill_buttons:hover:not(:last-child) {
  margin-right: 4rem;
}

.addSkill_buttons-active,
.addSkill_buttons-active:hover,
.addSkill_buttons-active:active,
.addSkill_buttons-active:focus {
  background-color: #00b6aa !important;
  color: #fff !important;
}

.btn-fixed-w {
  width: 100px !important;
  height: 30px;
  text-align: center;
  padding: 0 !important;
}

.progressBar_Allskills {
  display: flex;
  margin-top: 4rem;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .progressBar_Allskills {
    flex-wrap: wrap;
  }
}
.progressBar_Allskills.checkBox {
  margin-top: 6rem;
}
.progressBar_Allskills-description {
  width: 100px;
}
.progressBar_Allskills-bar {
  width: 40rem;
}
@media screen and (max-width: 767px) {
  .progressBar_Allskills-bar {
    width: 80%;
  }
}
.progressBar_Allskills-close {
  display: flex;
  align-items: center;
  flex-grow: 0.5;
  justify-content: center;
  margin-left: 4rem;
}
@media screen and (max-width: 575px) {
  .progressBar_Allskills-close {
    margin-top: 2rem;
    margin-left: 0.5rem;
  }
}
.progressBar_Allskills-close button {
  color: #00b6aa;
  background-color: transparent;
  border: 1px solid #00b6aa;
  border-radius: 4px;
  font-size: 20px;
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
@media screen and (max-width: 575px) {
  [type="checkbox"]:checked + label,
  [type="checkbox"]:not(:checked) + label {
    margin-left: 0;
  }
}

[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: solid 1px #00b6aa;
  background: #fff;
  border-radius: 2px;
}

[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
  content: "";
  width: 14px;
  height: 14px;
  background: #00a69c;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 2px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.custom-checkbox-label {
  font-size: 16px !important;
  font-weight: normal !important;
  color: #000000 !important;
}

.checkBox-container {
  display: flex;
  justify-content: space-between;
}


@media screen and (max-width: 575px) {
  .checkBox-container {
    margin-top: 2rem;
  }
}

