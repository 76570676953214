.header {
  margin: auto 10rem;
}
.resume_header {
  display: flex;
  align-items: center;
  margin-top: 14rem;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .header {
    margin: auto 2rem;
  }
}

.download_resume_header {
  font-size: 3rem;
  font-weight: 500;
}

.btn_home {
  background-image: url("./../../img/path-4.png");
  background-repeat: no-repeat;
  display: flex;
  background-position: 15px;
  padding: 1.3rem 2rem 1.3rem 5rem;
}

.resumeshow {
  display: flex;
  margin-top: 8rem;
  justify-content: space-around;
  flex-wrap: wrap;
}

.resume_item {
  margin: 1rem 2rem;
}

.download_btn {
  position: relative;
  margin-top: 2rem;
}

@media screen and (max-width: 767px) {
  .download_btn {
    width: 200px;
  }
}

.download_btn_div {
  display: flex;
  justify-content: space-around;
  background-color: white;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 3%;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  /* border-left: .15rem solid #00b6aa; */
  /* border-right: .15rem solid #00b6aa; */
  margin: auto 10px auto 2px;
  font-size: 1.6rem;
  width: 99%;
}

@media screen and (max-width: 767px) {
  .download_btn_div {
    width: 95%;
  }
}

.image_resume {
  width: 100%;
}
