
@media print
{
	#print
	{
		display: none;
	}
}
body
{
	/* margin: 0px; */
}

#print
{
	border: none;
    width: 50px;
    height: 50px;
    background-color: #a5a5a5;
    border-radius: 100%;
    position: fixed;
    top: 15%;
    left: 90%;
    transform: translate(-50%, -50%);
}
#container h1
{
	margin: 0px;
	padding: 0px;
	color: #000000;
}
#container h2
{
	font-weight: 400;
	color: #000000;
}
html {
	font-size: 62.5%;
}

#container{
	font-family: "Rubik", sans-serif;
	overflow-x: hidden;
	padding: 0;
	margin: 0;
	color: #4a4a4a;
}
.main_div ul, .main_div li 
{
	list-style-type: none;
}
#container
{
	margin-left: -8px;
}

#container header
{
	background-color: #e7f9f8;
    padding: 1.7rem 3.2rem;
    page-break-before: avoid;
    margin: 0px ;
}
.header_header
{
	margin-left: 0px;
}
#basic_info
{
	margin-left: 2.7rem;
    font-family: Rubik;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display: inline-block;
}
#contact
{
	display: flex;
    width: 70%;
    justify-content: space-between;
    font-size: 1.3rem;
	margin-top: 5px;
	padding: 0
}
#contact li
{
	display: inline-block;
    margin-right: 30%;
}
#head_shot_holder
{
	position: relative;
    width: 70px;
    height: 70px;
    float: left;
}
#head_shot
{
	position: absolute;
    top: 50%;
    border-radius: 100%;
    left: 50%;
    width: inherit;
    border: 3px solid #208eaf;
    transform: translate(-50%, -50%);
}
#name
{
	font-weight: 500;
	font-size: 2.2rem;
}
#degree
{
	font-weight: 400;
	font-size: 1.4rem;
	margin-top: 5px;
}
.education_item
{
	font-size: 1.4rem;
	margin: 0 0 6px 0;
}
.work_experience_item
{
	font-size: 1.4rem;
}
.extra_curricular_sub_block
{
	font-size: 1.4rem;
}
.skills_item
{
	display: inline-block;
    margin: 0 .7rem .7rem .7rem;
    font-size: 1.4rem;
    padding: 5px 15px;
    border-radius: 7px;
    background-color: #d0f4f1;
}
.CD_block, .CD_sub_header
{
	page-break-inside: avoid;
	margin: 0px;
	padding: 0px;
	margin-top: 1.7rem;
	margin-left: 1cm;
}
.language_item, .personal_item
{
	font-size: 1.4rem;
	margin-top: 1.2rem;
}
.language_item > span, .personal_item > span
{
	display: inline-block;
}
.language_name, .personal_type
{
	width: 10%;
}
.labguage_prof
{
	color: #007e76;
	margin-right: 8px;
}
.break_indicator
{
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: red;
	left: 0px;
}
.page_footer
{
	page-break-before: avoid;
	margin-left: 1cm;
	font-size: 1.4rem;
}
.page_footer img
{
	height: 25px;
	margin-left: 5px;
}
.break
{
	page-break-before: always;
	height: 37px;
	background-color: #d0f4f1;
}
#contact li
{
	display: flex;
	justify-content: space-between;
}
#contact > li > img 
{
	margin: 3px 5px 0 0;
	height: 10px;
}
.main_div *
{
	border: 0.1px solid transparent;
}
.extra_curricular_item
{
	margin: 6px 0 0 0;
}
.work_details
{
	word-break: break-all
}