.footer {
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .footer {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 767px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }
}

.link1,
.link2,
.footer_copyright {
  display: flex;
  align-items: center;
  justify-content: center;
}
.link1,
.footer_copyright {
  margin-left: 5%;
}
@media screen and (max-width: 767px) {
  .link1,
.footer_copyright {
  margin-left: 0;
}
}
.link2 {
  margin-right: 5%;
}

.footer_copyright {
  font-size: 1.4rem;
  color: #4a4a4a;
}

@media screen and (max-width: 767px) {
  .footer_copyright {
    margin-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .link2 {
    margin-top: 10px;
  }
}
.link_item {
  color: #4a4a4a;
  font-weight: bold !important;
  text-decoration: none;
}

.link_item:hover {
  color: #4a4a4a;
  font-weight: bold !important;
  text-decoration: none;
}
.item_space {
  margin: 0 2rem;
}
@media screen and (max-width: 767px) {
  .item_space {
    margin: 0 1rem;
  }
}
.powered {
  font-size: 2rem;
  font-weight: 500;
  margin-right: 20px;
}

.icon_space1 {
  margin: auto 2rem;
}
.icon_space2 {
  margin: auto 2rem auto 0;
}

.footer_margin1{
  margin-bottom: 5rem
}

@media screen and (max-width: 767px) {
  .footer_margin1{
    margin-bottom: auto
  }
}

.footer_margin2{
  margin-bottom: 3rem
}
