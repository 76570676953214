

.password_set_logo_height{
    height: 150px
}

@media screen and (max-width: 767px) {
    .password_set_logo_height {
      height: auto
    }  
  }