.profile-comp {
  height: 9rem;
  background-color: #f7f7f7;
  margin-top: 7.9rem;
  display: flex;
  align-items: center;
  color: black;
  font-weight: 500;
  font-size: 18px;
  /* position: fixed; */
  /* width:100vw; */
  /* z-index: 5000; */
}
@media screen and (max-width: 1023px) {
  .profile-comp {
    height: 16rem;
  }
}

.profile-comp-div {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

@media screen and (max-width: 1023px) {
  .profile-comp-div {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .profile-comp-div {
    flex-direction: column;
  }
}

.profile-comp-div1 {
  margin: 1.5rem auto;
  display: flex;
}

.profile-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}
@media screen and (max-width: 1023px) {
  .profile-align {
    flex-direction: column;
  }
}

.content {
  display: flex;
}
@media only screen and (max-width: 991px) {
  .content {
    flex-direction: column;
  }
}

.progress {
  border: 1px solid #00b6aa !important;
  width: 40%;
  height: 15px;
}

@media screen and (max-width: 1023px) {
  .progress {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .progress {
    width: 80%;
  }
}

.progress-bar {
  background-color: #00b6aa;
  font-size: 15px;
}

/* css for date picker */
.cXcRCo {
  box-shadow: 2px 2px 2px 2px #ccc;
  z-index: 1000;
}
.jgwYAS {
  z-index: 10000;
}

.btn-trans-findjobs {
  padding: 1rem 0rem !important;
  width: 9rem !important;
}
