html {
  font-size: 62.5%;
}
@media screen and (max-width: 1000px) {
  html {
    font-size: 55%;
  }
}
@media screen and (max-width: 800px) {
  html {
    font-size: 50%;
  }
}

body {
  font-family: "Rubik", sans-serif;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  width: 100%
}

label {
  color: #4a4a4a;
  margin-left: 7px;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: black;
}

input,
select {
  border: none !important;
  border-bottom: 1.5px solid #00b6aa !important;
  color: #4a4a4a !important;
  font-size: 16px !important;
}

.form-control:focus {
  outline: 0;
  border: none;
  border-bottom: 1.5px solid #00b6aa;
  box-shadow: none;
}

.btn:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
  background-color: #00b6aa;
  border: 1px solid #00b6aa;
}

.btn-primary:disabled {
  color: white;
  background-color: rgba(0, 182, 170, 0.6);
  border: 1px solid #00b6aa;
}

.btn-primary:disabled:hover {
  color: white;
  background-color: rgba(0, 182, 170, 0.6);
  border: 1px solid #00b6aa;
}

.btn-submit {
  background-color: #00b6aa;
  border: 1px solid #00b6aa;
  padding: 1rem 3rem;
  font-size: 16px;
  box-shadow: 1px 1px 1px #ccc !important;
}

.btn-submit:active {
  box-shadow: 3px 3px 3px #ccc !important;
}

.btn-submit:hover {
  background-color: #00b6aa;
  border: 1px solid #00b6aa;
}

.btn-trans {
  background-color: white;
  border: 1px solid #00b6aa;
  padding: 0.5rem 1.8rem;
  font-size: 16px;
  box-shadow: 1px 1px 1px #ccc;
  min-width: 10rem;
  color: #00b6aa;
  text-align: center;
}

.btn-trans table {
  margin: auto;
}

.btn-trans:hover {
  background-color: #00b6aa;
  border: 1px solid #00b6aa;
}

.btn-add {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1rem 2rem;
  font-size: 16px;
  /* width: 13rem; */
  /* box-shadow: 1px 1px 1px #ccc; */
  color: #007e76;
}

.btn-add:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: #00b6aa;
}

.btn-add:not(:disabled):not(.disabled):active:focus {
  background-color: transparent;
  border: 1px solid transparent;
  color: #00b6aa;
}
