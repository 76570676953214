.pop-up {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pop-up .message-div {
  height: 25rem;
  width: 60rem;
  background-color: white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  font-size: 2.2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around
}
