.formdiv {
  flex: 1;
  min-height: 125rem;
  padding: 5rem 5rem 5rem 3.2rem;
  color: black;
}

@media screen and (max-width: 1023px) {
  .formdiv {
    padding: 5rem 5rem 5rem 5rem;
  }
}

@media screen and (max-width: 767px) {
  .formdiv {
    padding: 5rem 2rem 5rem 2rem;
  }
}


.formdiv .profile-info-header {
  height: 6rem;
  background-color: #d0f4f1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 3.5%;
  padding-right: 5%;
  font-size: 1.6rem;
  margin-left: 2rem;
  margin-bottom: 5rem;
}

@media screen and (max-width: 1023px) {
  .formdiv .profile-info-header {
    margin-left: auto;
  }
}

.formdiv .form-group {
  padding: 1.5rem 10% 1.5rem 5%;
}

@media screen and (max-width: 1023px) {
  .formdiv .form-group {
    padding: 1.5rem 5% 1.5rem 5%;
  }
}

@media screen and (max-width: 767px) {
  .formdiv .form-group {
    padding: 1rem 5% 0rem 5%;
  }
}

.formdiv .error {
  color: red;
  font-size: 12px;
  padding: 5px;
}

/* for textPath */
.customtextarea {
  border: 1.5px solid #00b6aa;
  margin-top: 10px;
  font-size: 16px;
  color: #4a4a4a;
}
.customtextarea:focus {
  border: 1.5px solid #00b6aa;
}

.formdiv .custom_btn_div {
  margin: 7rem 7rem 25rem;
  display: flex;
  justify-content: space-between;

}

@media screen and (max-width: 767px) {
  .formdiv .custom_btn_div {
    margin: auto;
    position: fixed;
  bottom: 0%;
  /* height: 100px; */
  width: 90%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  background-color: white;
  }
}

.formdiv .topborder {
  border-top: 1px solid rgba(0, 182, 170, 0.26);
  width: 90%;
  margin: 3rem auto;
}

.formdiv .search_bar_w {
  width: 250px !important;
}

.formdiv .error-op {
  opacity: 0;
}

.btn-arrow-for {
  padding-bottom: 5px;
  /* padding-top: 5px; */
  font-size: 20px;
}

.btn-arrow-back {
  padding-bottom: 10px;
  font-size: 20px;
}

@media screen and (max-width: 1023px) {
  .fixed_btn_for {
   
    height: 53px
  }

  .fixed_btn_back {
    height: 53px
  }
}
