.sidebar {
  flex: 0 0 20%;
  padding: 5rem 0 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: black;
  padding-left: 5rem;
}

@media screen and (max-width: 1023px) {
  .sidebar {
    display: none
  }
}
.sidebar .form-progress {
  display: flex;
  flex-direction: column;
}
/* @media only screen and (max-width: 991px) {
  .sidebar .form-progress {
    flex-direction: row;
  }
} */
.sidebar .sidebar-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-width: 991px) {
  .sidebar .sidebar-logo {
    display: none;
  }
}
.sidebar .sidebar-logo .logo {
  margin: auto;
}
.sidebar .powered {
  font-size: 2.5rem;
  margin: auto;
  padding-top: 13px;
}

.progress-link {
  color: #d8d8d8;
  font-size: 1.6rem;
  margin-bottom: 1px;
  height: 60px;
  display: flex;
  align-items: center;
}

.progress-link-dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: auto 20px auto;
}

.progress-link-gdot {
  height: 10px;
  width: 10px;
  background-color: #00b6aa;
  border-radius: 50%;
  display: inline-block;
  margin: auto 20px auto;
}

.progress-link-idot {
  display: inline-block;
  margin: auto 20px auto;
  border: 2px solid #00b6aa;
  border-radius: 50%;
  background-color: #d0f4f1;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-link-idot .logo-fix {
  object-fit: contain;
}

.progress-item-i {
  border: 2px solid #00b6aa;
  padding: 7px 15px;
  background-color: #d0f4f1;
  margin-left: -20px;
  color: black;
  border-left-color: #d0f4f1;
  width: 180px;
  margin-left: -32px;
  margin-top: 0px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.backimage {
  margin-left: -25px;
}

.progress-item {
  color: black;
  padding-left: 10px;
}

.oncursor {
  cursor: pointer;
  margin: 5px 0;
}
