.add_certificate_text {
  margin-left: 8rem;
  color: black;
  font-size: 16px;
}

@media screen and (max-width: 1023px) {
  .add_certificate_text {
    margin-left: 5rem;
  }
}

@media screen and (max-width: 767px) {
  .add_certificate_text {
    margin-left: 2rem;
  }
}

.year-input {
  padding: 0.375rem 0.75rem !important;
  box-shadow: none;
  border: 0;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  height: auto;
}
.year-input:focus {
  /* border: 0 !important; */
  border-bottom: 1.5px solid #00b6aa;
}

.year-picker {
  width: 100% !important;
  border: 0;
}

.year-picker:hover {
  border-color: none;
}

.picker-panel {
  z-index: 1000;
}
.selected-year {
  background-color: #00b6aa !important;
}

.footer-today {
  color: #00b6aa !important;
}

.add_certificate {
  display: flex;
  align-items: center;
}

.add_certificate .add_certificate_btn {
  margin-left: 73%;
}

@media screen and (max-width: 1023px) {
  .add_certificate .add_certificate_btn {
    margin-left: 60%;
  }
}
@media screen and (max-width: 767px) {
  .add_certificate .add_certificate_btn {
    margin-left: 25%;
  }
}


