.forgot_password_div {
  margin-top: 20px;
}
.card-body .forgot_password_div .forgot_password {
  color: #00b6aa;
  text-decoration: none;
}

.new_user_link {
  font-size: 16px;
  margin-top: 20px;
}

.new_user_link a {
  color: #00b6aa;
  text-decoration: none;
}
.login_logo_height {
  height: 150px
}

@media screen and (max-width: 767px) {
  .login_logo_height {
    height: auto
  }  
}
