.searchBar-wrapper {
  padding: 1.5rem 5%;
  position: relative;
}

.searchBar_input_container {
  position: relative;
}

.searchBar-input {
  width: 40rem;
  padding: 1rem;
  padding-left: 0.5rem;
}
.searchBar-input::placeholder {
  color: #9b9b9b;
  font-size: 16px;
}
.searchBar-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #9b9b9b;
  font-size: 16px;
}
.searchBar-input:-ms-input-placeholder {
  /* IE 10+ */
  color: #9b9b9b;
  font-size: 16px;
}
.searchBar-input:-moz-placeholder {
  /* Firefox 18- */
  color: #9b9b9b;
  font-size: 16px;
}

.searchBar-button {
  padding: 0;
  background: none;
  border: none;
  height: 2rem;
  width: 2rem;
  margin-left: -28px;
}
.searchBar-button img {
  height: inherit;
}

.suggestion_container {
  border: 1px solid #00b6aa;
  margin-top: 1.5rem;
  border-radius: 4px;
  position: absolute;
  background-color: #fff;
  z-index: 3;
  box-shadow: 4px 7px 9px 0px #00000021;
}

@media screen and (max-width: 767px) {
  .suggestion_container {
    width:300px
  }
}

.suggestion_list {
  padding-inline-start: 0 !important;
  list-style: none;
  margin-bottom: 0;
  height: 44.4rem;
  overflow-y: scroll;
}

.suggestion_item {
  display: flex;
  justify-content: space-between;
  margin: 0 1.5rem;
  padding: 2rem 0;
  font-size: 1.4rem;
  letter-spacing: 0.51px;
  color: #4a4a4a;
  align-items: center;
}
.suggestion_item:not(:last-child) {
  border-bottom: solid 2px #ecedf4;
}

.suggestion_add {
  width: 9rem;
  height: 3rem;
  background-color: #00b6aa;
  border-radius: 0.4rem;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.79;
  letter-spacing: 0.51px;
}

.progressBar-container {
  padding: 1.5rem 6%;
  font-size: 16px;
}

.progressBar-heading {
  display: flex;
  margin-top: 8rem;
  /* padding-left: 5px; */
}

@media screen and (max-width: 767px) {
  .progressBar-heading {
    margin-top: 1rem;
  }
}

.searchBar-heading {
  font-size: 1.6rem;
  font-weight: 400;
}

.progressBar-skill {
  width: 200px;
}

.progressBar-strength {
  flex-grow: 1;
}

@media screen and (max-width: 767px) {
  .progressBar-skill {
    display: none;
  }

  .progressBar-strength {
    display: none;
  }
}

.progressBar_Allskills {
  display: flex;
  margin-top: 4rem;
  align-items: center;
}
.progressBar_Allskills-description {
  width: 100px;
  color: #4a4a4a;
}
.progressBar_Allskills-bar {
  width: 40rem;
}

@media screen and (min-width: 768px) {
  .progressBar_Allskills-bar {
    margin-left: 100px
  }
}

@media screen and (max-width: 767px) {
  .progressBar_Allskills-bar {
    width: 80%;
  }
}
.progressBar_Allskills-close {
  display: flex;
  align-items: center;
  flex-grow: 0.5;
  justify-content: center;
  margin-left: 4rem;
}

@media screen and (max-width: 575px) {
  .progressBar_Allskills-close {
    margin-top: 0rem;
    margin-left: 1rem;
  }
}
.progressBar_Allskills-close button {
  color: #00b6aa;
  background-color: transparent;
  border: 1px solid #00b6aa;
  border-radius: 4px;
  font-size: 20px;
}

.progressBar_scale {
  display: flex;
  font-size: 12px;
  margin-top: 1rem;
  justify-content: space-between;
}
.progressBar_scale span {
  margin-left: 1rem;
}

.custom-progress {
  background-color: #c8c7cc;
  display: flex;
}

.custom-progress-bar {
  background-color: #00b6aa;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.custom-progress-bar::after {
  content: " ";
  height: 25px;
  width: 25px;
  background-color: #00b6aa;
  border-radius: 50%;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  outline: none;
  border-bottom: none !important;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 1px;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: #00b6aa;
  border-radius: 1.3px;
}

input[type="range"]::-webkit-slider-thumb {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #00b6aa;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -12px;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  background: #00b6aa;
  border-radius: 1.3px;
}

input[type="range"]::-moz-range-thumb {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #00b6aa;
  border: none;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type="range"]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

.add_custom_skills_button {
  width: 100%;
  padding: 0.5rem;
  font-size: 14px;
  letter-spacing: 0.51px;
  color: #007e76;
  background: transparent;
  border: 1.5px solid #00b6aa;
  border-top: solid 1.5px #00b6aa;
  padding: 1.6rem 0;
}
.add_custom_skills_button img {
  width: 2rem;
  margin-right: 1rem;
}

.addSkill_overlay {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.addSkill_popUp {
  width: 55.5rem;
  height: 25rem;
  background-color: #fff;
  padding: 2.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.addSkill_header {
  display: flex;
  justify-content: space-between;
  font-size: 2.2rem;
  color: #007e76;
  font-weight: 500;
  align-items: center;
}
.addSkill_header h2 {
  font-size: inherit;
  margin: 0;
}

.addSkill_close {
  background-color: transparent;
  border: none;
  color: #00b6aa;
  font-size: 2.5rem;
}

.addSkill_wrapper {
  width: 46.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.addSkill_input {
  border-radius: 4px;
  border: solid 1px #00b6aa !important;
  padding: 0.8rem 2rem;
}

.addSkill_controls {
  display: flex;
  justify-content: flex-end;
}

.addSkill_buttons,
.addSkill_buttons:active,
.addSkill_buttons:focus,
.addSkill_buttons:hover {
  border: solid 1px #00b6aa;
  width: 138px;
  padding: 1.2rem 1.5rem 1.1rem;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  color: #007e76;
}
.addSkill_buttons:not(:last-child),
.addSkill_buttons:active:not(:last-child),
.addSkill_buttons:focus:not(:last-child),
.addSkill_buttons:hover:not(:last-child) {
  margin-right: 4rem;
}

.addSkill_buttons-active,
.addSkill_buttons-active:hover,
.addSkill_buttons-active:active,
.addSkill_buttons-active:focus {
  background-color: #00b6aa !important;
  color: #fff !important;
}

.btn-fixed-w {
  width: 100px !important;
  height: 30px;
  text-align: center;
  padding: 0 !important;
}
