.card-height {
  min-height: auto;
}

.group-margin {
  margin-bottom: 2.5rem;
}

.main-div {
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.main-div .inst-logo {
  margin: 25px !important;
}
@media screen and (max-width: 767px) {
  .main-div .inst-logo {
    margin: 20px !important;
  }
}

.main-div .auth_bg {
  min-height: 90vh;
  background-image: url("./../../img/group-3.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.card-custom {
  width: 46rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(168, 182, 200, 0.61);
  border: solid 1px rgba(168, 182, 200, 0.12);
  background-color: #ffffff;
  padding: 4.5rem 10px 2.7rem;
  font-size: 16px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .card-custom {
    width:30rem 
  }
}

.card-custom .login-header {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 3rem;
  color: black;
}

.footer_main {
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .footer_main {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 767px) {
  .footer_main {
    flex-direction: column;
    text-align: center;
  }
}
.footer_main .link1,
.footer_main .link2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_main .link1 {
  margin-left: 5%;
}
.footer_main .link2 {
  margin-right: 5%;
}
@media screen and (max-width: 767px) {
  .footer_main .link2 {
    margin-top: 10px;
  }
}
.footer_main .link-item {
  color: #4a4a4a;
  font-weight: bold !important;
  text-decoration: none;
}
.footer_main .item-space {
  margin: 0 2rem;
}
@media screen and (max-width: 767px) {
  .footer .item-space {
    margin: 0 1rem;
  }
}
.footer_main .powered {
  font-size: 2rem;
  font-weight: 500;
  margin-right: 20px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 10px;
}
